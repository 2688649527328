@import url(https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&family=Bangers&family=Bungee+Hairline&family=Bungee+Outline&family=Bungee+Shade&family=Grandstander:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Grenze+Gotisch:wght@400;600;700&family=Itim&family=Lemonada:wght@400;700&family=Lobster&family=Major+Mono+Display&family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&family=Open+Sans&family=Pacifico&family=Pattaya&family=Roboto&family=Roboto+Slab&family=Sansita+Swashed:wght@400;700&family=Sedgwick+Ave&family=Sigmar+One&family=VT323&display=swap);
.draftJsMentionPlugin__mention__29BEd, .draftJsMentionPlugin__mention__29BEd:visited {
  color: #575f67;
  cursor: pointer;
  display: inline-block;
  background: #e6f3ff;
  padding-left: 2px;
  padding-right: 2px;
  border-radius: 2px;
  text-decoration: none;
}

.draftJsMentionPlugin__mention__29BEd:hover, .draftJsMentionPlugin__mention__29BEd:focus {
  color: #677584;
  background: #edf5fd;
  outline: 0; /* reset for :focus */
}

.draftJsMentionPlugin__mention__29BEd:active {
  color: #222;
  background: #455261;
}
.draftJsMentionPlugin__mentionSuggestionsEntry__3mSwm {
  padding: 7px 10px 3px 10px;
  -webkit-transition: background-color 0.4s cubic-bezier(.27,1.27,.48,.56);
  -moz-transition: background-color 0.4s cubic-bezier(.27,1.27,.48,.56);
  transition: background-color 0.4s cubic-bezier(.27,1.27,.48,.56);
}

.draftJsMentionPlugin__mentionSuggestionsEntry__3mSwm:active {
  background-color: #cce7ff;
}

.draftJsMentionPlugin__mentionSuggestionsEntryFocused__3LcTd {
  background-color: #e6f3ff;
}

.draftJsMentionPlugin__mentionSuggestionsEntryText__3Jobq {
  display: inline-block;
  margin-left: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 368px;
  font-size: 0.9em;
  margin-bottom: 0.2em;
}

.draftJsMentionPlugin__mentionSuggestionsEntryAvatar__1xgA9 {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 12px;
}
.draftJsMentionPlugin__mentionSuggestions__2DWjA {
  border: 1px solid #eee;
  margin-top: 0.4em;
  position: absolute;
  min-width: 220px;
  max-width: 440px;
  background: #fff;
  border-radius: 2px;
  -webkit-box-shadow: 0px 4px 30px 0px rgba(220,220,220,1);
          box-shadow: 0px 4px 30px 0px rgba(220,220,220,1);
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
  z-index: 2;
  display: -moz-box;
  display: flex;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
       flex-direction: column;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transform: scale(0);
          -moz-transform: scale(0);
       transform: scale(0);
}
.cus-mention {
  color: #0b7bff
}

.cus-point {
  color: #6fcf97
}
.draftJsEmojiPlugin__emojiSelect__34S1B {
  display: inline-block;
}

.draftJsEmojiPlugin__emojiSelectButton__3sPol, .draftJsEmojiPlugin__emojiSelectButtonPressed__2Tezu {
  margin: 0;
  padding: 0;
  width: 2.5em;
  height: 1.5em;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  line-height: 1.2em;
  font-size: 1.5em;
  color: #888;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 1.5em;
  cursor: pointer;
}

.draftJsEmojiPlugin__emojiSelectButton__3sPol:focus, .draftJsEmojiPlugin__emojiSelectButtonPressed__2Tezu:focus {
  outline: 0;
  /* reset for :focus */
}

.draftJsEmojiPlugin__emojiSelectButton__3sPol:hover, .draftJsEmojiPlugin__emojiSelectButtonPressed__2Tezu:hover {
  background: #f3f3f3;
}

.draftJsEmojiPlugin__emojiSelectButton__3sPol:active, .draftJsEmojiPlugin__emojiSelectButtonPressed__2Tezu:active {
  background: #e6e6e6;
}

.draftJsEmojiPlugin__emojiSelectButtonPressed__2Tezu {
  background: #ededed;
}

.draftJsEmojiPlugin__emojiSelectPopover__1J1s0 {
  margin-top: 10px;
  padding: 0 .3em;
  position: absolute;
  z-index: 1000;
  -webkit-box-sizing: content-box;
     -moz-box-sizing: content-box;
          box-sizing: content-box;
  background: #fff;
  border: 1px solid #e0e0e0;
  -webkit-box-shadow: 0 4px 30px 0 gainsboro;
          box-shadow: 0 4px 30px 0 gainsboro;
}

.draftJsEmojiPlugin__emojiSelectPopoverClosed__3Kxxq {
  display: none;
}

.draftJsEmojiPlugin__emojiSelectPopoverTitle__3tpXz {
  margin: 0 0 .3em;
  padding-left: 1em;
  height: 2.5em;
  line-height: 2.5em;
  font-weight: normal;
  font-size: 1em;
  color: #9e9e9e;
}

.draftJsEmojiPlugin__emojiSelectPopoverGroups__35t9m {
  margin: 0 0 .3em;
  position: relative;
  z-index: 0;
  width: 21em;
  height: 20em;
}

.draftJsEmojiPlugin__emojiSelectPopoverGroup__3zwcE {
  padding: 0 .5em;
}

.draftJsEmojiPlugin__emojiSelectPopoverGroup__3zwcE:first-child .draftJsEmojiPlugin__emojiSelectPopoverGroupTitle__2pC51 {
  display: none;
}

.draftJsEmojiPlugin__emojiSelectPopoverGroupTitle__2pC51 {
  margin: 1em 0;
  padding-left: .5em;
  font-weight: normal;
  font-size: 1em;
  color: #9e9e9e;
}

.draftJsEmojiPlugin__emojiSelectPopoverGroupList__HQ8_y {
  margin: 0;
  padding: 0;
  display: -moz-box;
  display: flex;
  list-style: none;
  flex-wrap: wrap;
}

.draftJsEmojiPlugin__emojiSelectPopoverGroupItem__2pFOS {
  width: 2.5em;
  height: 2.5em;
}

.draftJsEmojiPlugin__emojiSelectPopoverToneSelect__28bny {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
}

.draftJsEmojiPlugin__emojiSelectPopoverToneSelectList__haFSJ {
  margin: .3em;
  padding: .3em;
  position: absolute;
  display: -moz-box;
  display: flex;
  list-style: none;
  border: 1px solid #e0e0e0;
  border-radius: .5em;
  background: #fff;
  -webkit-box-shadow: 0 0 0.3em rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 0.3em rgba(0, 0, 0, 0.1);
}

.draftJsEmojiPlugin__emojiSelectPopoverToneSelectItem__2SgvL {
  width: 2.5em;
  height: 2.5em;
}

.draftJsEmojiPlugin__emojiSelectPopoverToneSelectItem__2SgvL:first-child {
  border-right: 1px solid #e0e0e0;
}

.draftJsEmojiPlugin__emojiSelectPopoverEntry__1ErDJ, .draftJsEmojiPlugin__emojiSelectPopoverEntryFocused__M28XS {
  padding: 0;
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  outline: none;
  -webkit-transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
  -moz-transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
  transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
}

.draftJsEmojiPlugin__emojiSelectPopoverEntryFocused__M28XS {
  background-color: #efefef;
}

.draftJsEmojiPlugin__emojiSelectPopoverEntryIcon__1yNaC {
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
}

.draftJsEmojiPlugin__emojiSelectPopoverNav__1Nzd7 {
  margin: 0;
  padding: 0 .5em;
  display: -moz-box;
  display: flex;
  width: 20em;
  list-style: none;
}

.draftJsEmojiPlugin__emojiSelectPopoverNavItem__qydCX {
  width: 2.5em;
  height: 2.5em;
}

.draftJsEmojiPlugin__emojiSelectPopoverNavEntry__1OiGB, .draftJsEmojiPlugin__emojiSelectPopoverNavEntryActive__2j-Vk {
  padding: 0;
  width: 100%;
  height: 100%;
  font-size: 1.2em;
  color: #bdbdbd;
  background: none;
  border: none;
  outline: none;
}

.draftJsEmojiPlugin__emojiSelectPopoverNavEntryActive__2j-Vk {
  color: #42a5f5;
}

.draftJsEmojiPlugin__emojiSelectPopoverScrollbar__1Xjc6 {
  position: absolute;
  right: 0;
  top: .3em;
  bottom: .3em;
  width: .25em;
  background-color: #e0e0e0;
  border-radius: .125em;
  opacity: .1;
  -webkit-transition: opacity .4s;
  -moz-transition: opacity .4s;
  transition: opacity .4s;
}

.draftJsEmojiPlugin__emojiSelectPopoverScrollbarThumb__jGYdG {
  background-color: #000;
  border-radius: .125em;
  cursor: pointer;
}

.draftJsEmojiPlugin__emojiSelectPopoverGroups__35t9m:hover .draftJsEmojiPlugin__emojiSelectPopoverScrollbar__1Xjc6 {
  opacity: .3;
}

.draftJsEmojiPlugin__emojiSelectPopoverGroups__35t9m .draftJsEmojiPlugin__emojiSelectPopoverScrollbar__1Xjc6:hover,
.draftJsEmojiPlugin__emojiSelectPopoverGroups__35t9m .draftJsEmojiPlugin__emojiSelectPopoverScrollbar__1Xjc6:active {
  opacity: .6;
}
.draftJsEmojiPlugin__emoji__2oqBk {
  background-position: center;
  /* make sure the background the image is only shown once */
  background-repeat: no-repeat;
  background-size: contain;
  /* move it a bit further down to align it nicer with the text */
  vertical-align: middle;

  /*
  We need to limit the emoji width because it can be multiple characters
  long if it is a 32bit unicode. Since the proper width depends on the font and
  it's relationship between 0 and other characters it's not ideal. 1.95ch is not
  the best value, but hopefully a good enough approximation for most fonts.
  */
  display: inline-block;
  overflow: hidden;
  max-width: 1.95ch;
  /*
  Needed for iOS rendering to avoid some icons using a lot of height without
  actually needing it.
  */
  max-height: 1em;
  line-height: inherit;
  margin: -.2ex 0em .2ex;
  /*
  In the past we used opacity: 0 to hide the original Emoji icon no matter what
  system it is. Recently we switched to color: transparent since it started to
  work in recent iOS version.
  */
  color: transparent;

  /*
  Some SVG files (say 2764 for :heart:) don't have default width/height, thus
  may not be rendered properly on some platforms/browsers (e.g., Windows 10 +
  Chrome 61).
  */
  min-width: 1em;
}
.draftJsEmojiPlugin__emojiSuggestionsEntry__2-2p_ {
  padding: 5px 10px 1px 10px;
  -webkit-transition: background-color 0.4s cubic-bezier(.27,1.27,.48,.56);
  -moz-transition: background-color 0.4s cubic-bezier(.27,1.27,.48,.56);
  transition: background-color 0.4s cubic-bezier(.27,1.27,.48,.56);
}

.draftJsEmojiPlugin__emojiSuggestionsEntry__2-2p_:active {
  background-color: #cce7ff;
}

.draftJsEmojiPlugin__emojiSuggestionsEntryFocused__XDntY {
  background-color: #e6f3ff;
}

.draftJsEmojiPlugin__emojiSuggestionsEntryText__2sPjk {
  display: inline-block;
  margin-left: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 368px;
  font-size: 0.9em;
}

.draftJsEmojiPlugin__emojiSuggestionsEntryIcon__1qC2V {
  width: 1em;
  height: 1em;
  margin-left: 0.25em;
  margin-right: 0.25em;
  display: inline-block;
}
.draftJsEmojiPlugin__emojiSuggestions__2ffcV {
  border: 1px solid #eee;
  margin-top: 1.75em;
  position: absolute;
  min-width: 220px;
  max-width: 440px;
  background: #fff;
  border-radius: 2px;
  -webkit-box-shadow: 0px 4px 30px 0px rgba(220,220,220,1);
          box-shadow: 0px 4px 30px 0px rgba(220,220,220,1);
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
  z-index: 2;
  display: -moz-box;
  display: flex;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
       flex-direction: column;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transform: scale(0);
          -moz-transform: scale(0);
       transform: scale(0);
}

.draftJsFocusPlugin__unfocused__1Wvrs:hover {
  cursor: default;
  border-radius: 2px;
  -webkit-box-shadow: 0 0 0 3px #D2E3F7;
          box-shadow: 0 0 0 3px #D2E3F7;
}

.draftJsFocusPlugin__focused__3Mksn {
  cursor: default;
  border-radius: 2px;
  -webkit-box-shadow: 0 0 0 3px #ACCEF7;
          box-shadow: 0 0 0 3px #ACCEF7;
}

.image-gallery-icon {
  color: #fff;
  -webkit-transition: all .2s ease-out;
  -moz-transition: all .2s ease-out;
  transition: all .2s ease-out;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: none;
  position: absolute;
  z-index: 4;
  -webkit-filter: drop-shadow(0 2px 2px #1a1a1a);
          filter: drop-shadow(0 2px 2px #1a1a1a); }
  @media (min-width: 768px) {
    .image-gallery-icon:hover {
      color: #337ab7; }
      .image-gallery-icon:hover .image-gallery-svg {
        -webkit-transform: scale(1.1);
           -moz-transform: scale(1.1);
                transform: scale(1.1); } }
  .image-gallery-icon:focus {
    outline: 2px solid #337ab7; }

.image-gallery-using-mouse .image-gallery-icon:focus {
  outline: none; }

.image-gallery-fullscreen-button,
.image-gallery-play-button {
  bottom: 0;
  padding: 20px; }
  .image-gallery-fullscreen-button .image-gallery-svg,
  .image-gallery-play-button .image-gallery-svg {
    height: 36px;
    width: 36px; }
  @media (max-width: 768px) {
    .image-gallery-fullscreen-button,
    .image-gallery-play-button {
      padding: 15px; }
      .image-gallery-fullscreen-button .image-gallery-svg,
      .image-gallery-play-button .image-gallery-svg {
        height: 24px;
        width: 24px; } }
  @media (max-width: 480px) {
    .image-gallery-fullscreen-button,
    .image-gallery-play-button {
      padding: 10px; }
      .image-gallery-fullscreen-button .image-gallery-svg,
      .image-gallery-play-button .image-gallery-svg {
        height: 16px;
        width: 16px; } }

.image-gallery-fullscreen-button {
  right: 0; }

.image-gallery-play-button {
  left: 0; }

.image-gallery-left-nav,
.image-gallery-right-nav {
  padding: 50px 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
     -moz-transform: translateY(-50%);
          transform: translateY(-50%); }
  .image-gallery-left-nav .image-gallery-svg,
  .image-gallery-right-nav .image-gallery-svg {
    height: 120px;
    width: 60px; }
  @media (max-width: 768px) {
    .image-gallery-left-nav .image-gallery-svg,
    .image-gallery-right-nav .image-gallery-svg {
      height: 72px;
      width: 36px; } }
  @media (max-width: 480px) {
    .image-gallery-left-nav .image-gallery-svg,
    .image-gallery-right-nav .image-gallery-svg {
      height: 48px;
      width: 24px; } }
  .image-gallery-left-nav[disabled],
  .image-gallery-right-nav[disabled] {
    cursor: disabled;
    opacity: .6;
    pointer-events: none; }

.image-gallery-left-nav {
  left: 0; }

.image-gallery-right-nav {
  right: 0; }

.image-gallery {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  position: relative; }
  .image-gallery.fullscreen-modal {
    background: #000;
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 5; }
    .image-gallery.fullscreen-modal .image-gallery-content {
      top: 50%;
      -webkit-transform: translateY(-50%);
         -moz-transform: translateY(-50%);
              transform: translateY(-50%); }

.image-gallery-content {
  position: relative;
  line-height: 0;
  top: 0; }
  .image-gallery-content.fullscreen {
    background: #000; }
  .image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: -moz-calc(100vh - 80px);
    max-height: calc(100vh - 80px); }
  .image-gallery-content.left .image-gallery-slide .image-gallery-image, .image-gallery-content.right .image-gallery-slide .image-gallery-image {
    max-height: 100vh; }

.image-gallery-slide-wrapper {
  position: relative; }
  .image-gallery-slide-wrapper.left, .image-gallery-slide-wrapper.right {
    display: inline-block;
    width: -moz-calc(100% - 110px);
    width: calc(100% - 110px); }
    @media (max-width: 768px) {
      .image-gallery-slide-wrapper.left, .image-gallery-slide-wrapper.right {
        width: -moz-calc(100% - 87px);
        width: calc(100% - 87px); } }
  .image-gallery-slide-wrapper.image-gallery-rtl {
    direction: rtl; }

.image-gallery-slides {
  line-height: 0;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  text-align: center; }

.image-gallery-slide {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%; }
  .image-gallery-slide.center {
    position: relative; }
  .image-gallery-slide .image-gallery-image {
    width: 100%;
    object-fit: contain; }
  .image-gallery-slide .image-gallery-description {
    background: rgba(0, 0, 0, 0.4);
    bottom: 70px;
    color: #fff;
    left: 0;
    line-height: 1;
    padding: 10px 20px;
    position: absolute;
    white-space: normal; }
    @media (max-width: 768px) {
      .image-gallery-slide .image-gallery-description {
        bottom: 45px;
        font-size: .8em;
        padding: 8px 15px; } }

.image-gallery-bullets {
  bottom: 20px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  width: 80%;
  z-index: 4; }
  .image-gallery-bullets .image-gallery-bullets-container {
    margin: 0;
    padding: 0;
    text-align: center; }
  .image-gallery-bullets .image-gallery-bullet {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 50%;
    -webkit-box-shadow: 0 1px 0 #1a1a1a;
            box-shadow: 0 1px 0 #1a1a1a;
    cursor: pointer;
    display: inline-block;
    margin: 0 5px;
    outline: none;
    padding: 5px;
    -webkit-transition: background .2s ease-out;
    -moz-transition: background .2s ease-out;
    transition: background .2s ease-out; }
    @media (max-width: 768px) {
      .image-gallery-bullets .image-gallery-bullet {
        margin: 0 3px;
        padding: 3px; } }
    @media (max-width: 480px) {
      .image-gallery-bullets .image-gallery-bullet {
        padding: 2.7px; } }
    .image-gallery-bullets .image-gallery-bullet:focus, .image-gallery-bullets .image-gallery-bullet:hover {
      background: #337ab7;
      -webkit-transform: scale(1.1);
         -moz-transform: scale(1.1);
              transform: scale(1.1); }
    .image-gallery-bullets .image-gallery-bullet.active {
      background: #fff; }

.image-gallery-thumbnails-wrapper {
  position: relative; }
  .image-gallery-thumbnails-wrapper.thumbnails-wrapper-rtl {
    direction: rtl; }
  .image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
    display: inline-block;
    vertical-align: top;
    width: 100px; }
    @media (max-width: 768px) {
      .image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
        width: 81px; } }
    .image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails, .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails {
      height: 100%;
      width: 100%;
      left: 0;
      padding: 0;
      position: absolute;
      top: 0; }
      .image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail, .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail {
        display: block;
        margin-right: 0;
        padding: 0; }
        .image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail + .image-gallery-thumbnail, .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail + .image-gallery-thumbnail {
          margin-left: 0;
          margin-top: 2px; }
  .image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
    margin: 0 5px; }
    @media (max-width: 768px) {
      .image-gallery-thumbnails-wrapper.left, .image-gallery-thumbnails-wrapper.right {
        margin: 0 3px; } }

.image-gallery-thumbnails {
  overflow: hidden;
  padding: 5px 0; }
  @media (max-width: 768px) {
    .image-gallery-thumbnails {
      padding: 3px 0; } }
  .image-gallery-thumbnails .image-gallery-thumbnails-container {
    cursor: pointer;
    text-align: center;
    -webkit-transition: -webkit-transform .45s ease-out;
    transition: -webkit-transform .45s ease-out;
    -moz-transition: transform .45s ease-out, -moz-transform .45s ease-out;
    transition: transform .45s ease-out;
    transition: transform .45s ease-out, -webkit-transform .45s ease-out, -moz-transform .45s ease-out;
    white-space: nowrap; }

.image-gallery-thumbnail {
  display: inline-block;
  border: 4px solid transparent;
  -webkit-transition: border .3s ease-out;
  -moz-transition: border .3s ease-out;
  transition: border .3s ease-out;
  width: 100px;
  background: transparent;
  padding: 0; }
  @media (max-width: 768px) {
    .image-gallery-thumbnail {
      border: 3px solid transparent;
      width: 81px; } }
  .image-gallery-thumbnail + .image-gallery-thumbnail {
    margin-left: 2px; }
  .image-gallery-thumbnail .image-gallery-thumbnail-inner {
    position: relative; }
  .image-gallery-thumbnail .image-gallery-thumbnail-image {
    vertical-align: middle;
    width: 100%;
    line-height: 0; }
  .image-gallery-thumbnail.active, .image-gallery-thumbnail:hover, .image-gallery-thumbnail:focus {
    outline: none;
    border: 4px solid #337ab7; }
    @media (max-width: 768px) {
      .image-gallery-thumbnail.active, .image-gallery-thumbnail:hover, .image-gallery-thumbnail:focus {
        border: 3px solid #337ab7; } }

.image-gallery-thumbnail-label {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  color: white;
  font-size: 1em;
  left: 0;
  line-height: 1em;
  padding: 5%;
  position: absolute;
  top: 50%;
  text-shadow: 1px 1px 0 black;
  -webkit-transform: translateY(-50%);
     -moz-transform: translateY(-50%);
          transform: translateY(-50%);
  white-space: normal;
  width: 100%; }
  @media (max-width: 768px) {
    .image-gallery-thumbnail-label {
      font-size: .8em;
      line-height: .8em; } }

.image-gallery-index {
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  line-height: 1;
  padding: 10px 20px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 4; }
  @media (max-width: 768px) {
    .image-gallery-index {
      font-size: .8em;
      padding: 5px 10px; } }

.image-gallery-right-nav, .image-gallery-left-nav {
  padding: 0;
  width: 56px;
  height: 56px;
  margin: 0 32px;
  border-radius: 50%;
  background: rgba(245, 245, 245, 0.4);
}
.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  width: 22px;
  height: auto;
}

.image-gallery-right-nav .image-gallery-svg {
  margin-left: 4px;
}
.image-gallery-left-nav .image-gallery-svg {
  margin-right: 4px;
}

.image-gallery-slide .image-gallery-image {
  width: auto;
  max-width: 100%;
  object-fit: contain;
}

.image-gallery-slide.center div{
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  margin: 0 auto;
}
.poll-editor .DraftEditor-root {
  min-height: 100px;
}

.option-item {
  height: 40px;
  padding: 8px;
  margin: 5px 0px;
  display: -moz-box;
  display: flex;
  border-radius: 8px;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  border: 1px solid #eaeaea;
  -moz-box-pack: justify;
       justify-content: space-between;
}

.option-item input {
  border: none;
}

.create-poll-modal .ant-modal-title,
.edit-profile-modal .ant-modal-title {
  font-size: 22px;
  font-weight: 500;
  font-family: 'Poppins';
}
.create-poll-modal .ant-modal-body {
  padding-top: 12px;
}

.create-poll-modal .ant-checkbox-wrapper {
  margin: 8px 4px;
}

.create-poll-modal .ant-checkbox-checked .ant-checkbox-inner {
  border-color: #060606;
  background-color: #060606;
}

.create-poll-modal .ant-col.ant-form-item-label {
  padding-bottom: 0;
}

.create-poll-modal label[for='poll_date']:before {
  display: none;
}

.create-poll-modal .ant-modal-content {
  height: 100%;
  display: -moz-box;
  display: flex;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
       flex-direction: column;
  border-radius: 12px;
}

.create-poll-modal .ant-modal-header {
  border-radius: 12px 12px 0 0;
}

.poll__adding-member {
  top: 0;
  left: 0;
  bottom: 0px;
  width: 520px;
  z-index: 100;
  border: none;
  border-radius: 12px;
  position: absolute;
  -webkit-transition: left 0.3s linear;
  -moz-transition: left 0.3s linear;
  transition: left 0.3s linear;
  margin: 0;
}
.poll__adding-member .ant-form-item-control-input {
  height: 100%;
}
.poll__adding-member .ant-form-item-control-input-content {
  height: 100%;
}
.poll__adding-member .ant-form-item-control-input-content > div {
  border: none;
}

.poll__adding-member .back-button {
  background-color: #eaeaea;
}

.poll__adding-member .ant-input-affix-wrapper {
  width: 78%;
}

.poll__adding-member.hide {
  left: 520px;
  overflow: hidden;
}

.poll__adding-member .list-member {
  display: block;
  overflow: auto;
  height: 100%;
}

.create-poll-modal .separate {
  height: 8px;
  width: 520px;
  margin-left: -24px;
  background-color: #fafafa;
}

.selected-user-avatars {
  height: 60px;
  width: 520px;
  margin: 10px 0;
  padding: 0 24px;
  margin-right: -24px;
  margin-left: -24px;
  -moz-box-pack: justify;
       justify-content: space-between;
  border-top: solid 8px #fafafa;
  border-bottom: solid 8px #fafafa;
}

.create-poll-modal .participants-list {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
       align-items: center;
  -moz-box-pack: justify;
       justify-content: space-between;
}
.create-poll-modal .participants-list.total-1 .member-avatars {
  width: 32px;
}
.create-poll-modal .participants-list.total-2 .member-avatars {
  width: 48px;
}
.create-poll-modal .participants-list.total-3 .member-avatars {
  width: 54px;
}
.create-poll-modal .participants-list .member-avatars {
  width: 60px;
  height: 32px;
  margin: 0 5px;
  position: relative;
}
.create-poll-modal .participants-list .has-more {
  margin: 0 5px;
}
.create-poll-modal .participants-list .member-avatars > img {
  position: absolute;
  border-radius: 8px;
  border: solid 1px #fafafa;
  background-color: #ffffff;
}

.create-poll-modal .participants-list .member-avatars > img:first {
  left: 0;
}
.create-poll-modal .participants-list .member-avatars > img:nth-child(2) {
  left: 16px;
}
.create-poll-modal .participants-list .member-avatars > img:nth-child(3) {
  left: 32px;
}

.main-container .participants-list {
  height: 36px;
}
.main-container .participants-list .member-avatars > img {
  width: 21px;
  height: 21px;
  position: absolute;
  border-radius: 6px;
  border: solid 1px #fafafa;
  background-color: #ffffff;
}
.main-container .participants-list .member-avatars > img:first {
  left: 0;
}
.main-container .participants-list .member-avatars > img:nth-child(2) {
  left: 11px;
}
.main-container .participants-list .member-avatars > img:nth-child(3) {
  left: 22px;
}

.main-container .participants-list .member-avatars {
  width: 48px;
  position: relative;
}

.main-container .participants-list.total-1 .member-avatars {
  width: 22px;
}
.main-container .participants-list.total-2 .member-avatars {
  width: 33px;
}
.main-container .participants-list.total-3 .member-avatars {
  width: 44px;
}


.sticker-picker{
    max-width: 266px;
}

.sticker-picker .sp-tab-head .arrow-prev,
.sticker-picker .sp-tab-head .arrow-next{
    padding: 0 5px;
}

.sticker-picker .sp-tab-head-item {
    -webkit-transition: 0.5s ease;
    -moz-transition: 0.5s ease;
    transition: 0.5s ease;
}

.sticker-picker .sp-tab-head-item.active {
    background-color: #e2e2e2;
}

.sticker-picker .sp-tab-head-item img {
    width: 25px;
    height: 25px;
    margin: 7.5px;
}

.sticker-picker .sp-sticker-container {
    display: -moz-box;
    display: flex;
    flex-wrap: wrap;
    -moz-box-align: center;
         align-items: center;
    padding: 5px;
    max-height: 210px;
    overflow-y: auto;
}

.sticker-picker .sp-sticker-item {
    cursor: pointer;
    -webkit-transition: 0.5s ease;
    -moz-transition: 0.5s ease;
    transition: 0.5s ease;
}

.sticker-picker .sp-sticker-item:hover{
    background-color: #d2d2d2;
    border-radius: 8px;
}

.sticker-picker .sp-sticker-item img {
    width: 64px;
    height: 64px;
}
body {
  background-color: #f2f4f4;
  font-size: 100%;
  line-height: 1.4;
  margin: 0;
  font-family: 'Nunito Sans', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*html:not([data-scroll='0']) {*/
/*  padding-top: 64px;*/
/*}*/

html:not([data-scroll='0']) header {
  position: -webkit-sticky;
  position: sticky;
  z-index: 100;
  top: 0;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.form-total-comment {
  display: -moz-box;
  display: flex;
  background-color: #ffffff;
  border: 1px solid #efefef;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 6px;
  width: 100%;
  padding: 7px 10px;
  margin-left: 15px;
  cursor: text;
  position: relative;
}

.form-total-comment ::-webkit-scrollbar {
  width: 2px;
}

.form-total-comment ::-webkit-scrollbar-track {
  background: white;
}

.form-total-comment ::-webkit-scrollbar-thumb {
  background: #888;
}
.modal-preview-content .ant-modal-content {
  -webkit-box-shadow: none;
          box-shadow: none;
  height: 100%;
  overflow-y: auto;
}
.modal-preview-content .ant-modal-header {
  border-bottom: 0;
}
.modal-preview-content .ant-modal-body {
  font-size: 16px;
}
.input-comment-post {
  border-radius: 6px;
  width: 100%;
  resize: none;
  border: none;
  outline: none;
}
._4w79 {
  cursor: text;
  -moz-box-flex: 1;
       flex: 1 1 auto;
  line-height: 16px;
  overflow: hidden;
  color: #8d949e;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.list-interactive {
  list-style: none;
  display: -moz-box;
  display: flex;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
       flex-direction: row;
  margin: 0px;
  padding: 0px;
  -moz-box-align: end;
       align-items: flex-end;
}
.ant-input:focus {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}
/* customer date picker ant design */
.ant-layout-content {
  padding-left: 32px;
  padding-right: 32px;
}
.ant-layout-content > div {
  -webkit-transition: height 2s;
  -moz-transition: height 2s;
  transition: height 2s;
}
.typing-ellipsis {
  position: absolute;
  bottom: -18px;
  left: 0;
}
.comment-count {
  position: absolute;
  bottom: -18px;
  right: 0;
  font-size: 11px;
}

/* customer tool-tip ant design  START */
.ant-tooltip .ant-tooltip-inner {
  color: #000;
  background-color: #fff;
  -webkit-box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  font-size: 0.875rem;
}
.ant-tooltip .ant-tooltip-arrow::before {
  background-color: #fff;
}
.ant-tooltip-arrow span.ant-tooltip-arrow-content {
  background-color: #fff;
}

/* customer tool-tip ant design  END */

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #f5f5f5;
}
.popup-date-picker {
  border-radius: 8px;
  overflow: hidden;
  -webkit-box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.08);
          box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.08);
}
.ant-picker-header {
  border-bottom: 0;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 0;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner:hover {
  border: 0;
  background: rgba(0, 0, 0, 0.8);
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: #060606;
}
.ant-picker-cell .ant-picker-cell-inner {
  width: 33px;
  height: 33px;
  display: -moz-box;
  display: flex;
  -moz-box-pack: center;
       justify-content: center;
  -moz-box-align: center;
       align-items: center;
  border-radius: 50%;
}
.ant-picker-cell-today .ant-picker-cell-inner {
  background: rgba(6, 6, 6, 0.05);
  border-radius: 50%;
}
.ant-picker-content th {
  color: #8b8b8b;
}
/* .ant-picker-header-super-prev-btn {
  display: none;
}
.ant-picker-header-super-next-btn {
  display: none;
} */
.ant-picker-month-btn {
  font-size: 1rem;
  font-weight: 600;
}
.ant-picker-year-btn {
  font-size: 1rem;
  font-weight: 600;
}
.ant-picker-header {
  padding-top: 10px;
}

.ant-picker.heatmap-picker {
  border-radius: 4px;
  background-color: #f5f5f5 !important;
  width: 100%;
}

.heatmap-picker input {
  cursor: pointer;
}

.ant-picker-input input {
  font-size: 1rem;
  font-weight: 500;
  color: #060606;
}

/* end customer date picker ant design */
.h-full {
  height: 100%;
}
.w-full {
  width: 100%;
}
.pt-8 {
  padding-top: 8px;
}
.mb-16 {
  margin-bottom: 16px;
}
.mt-16 {
  margin-top: 16px;
}
.outside-container {
  background-color: #fafafa;
}
.layout-outside {
  max-width: 1200px;
  width: 100%;
  height: 100vh;
  margin: auto;
}
@media only screen and (max-width: 1200px) {
  .layout-outside {
    padding-left: 66px;
    padding-right: 66px;
  }
}
/* custom drop down menu */

/* .ant-dropdown-placement-bottomRight { */
.ant-dropdown-menu-light {
  padding: 0px !important;
  background-color: #ffffff !important;
  -webkit-box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.08) !important;
          box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.08) !important;
  border-radius: 3px !important;
  color: #333333 !important;
  font-size: 16px !important;
}
.ant-popover-inner-content {
  padding: 4px 8px 4px 8px !important;
  border-radius: 6px !important;
}
/* } */
.ant-dropdown-menu {
  padding-top: 12px;
  padding-bottom: 12px;
}
.ant-dropdown-menu-item {
  padding-left: 14px;
  padding-right: 14px;
}
.ant-dropdown-menu-item:not(:last-child) {
  margin-bottom: 18px;
}
.icon-clock {
  margin-top: 13px;
}

.history-copy .ant-dropdown-menu {
  padding-top: 6px;
  padding-bottom: 6px;
}
.ant-select-item {
  min-height: auto;
}

.ant-comment-avatar img {
  border-radius: 6px;
}

.ant-comment-content {
  background: #f5f5f5;
  border-radius: 6px;
  padding: 9px 14px 7px 16px;
}

.ant-comment {
  width: 100%;
}
/* customer search input ant */
.ant-input-search-icon::before {
  border-left: none;
}
/* end customer search input ant */
::-webkit-input-placeholder {
  font-size: 16px;
}
:-ms-input-placeholder {
  font-size: 16px;
}
::placeholder {
  font-size: 16px;
}

.ant-result-extra {
  display: -moz-box;
  display: flex;
  -moz-box-pack: center;
       justify-content: center;
}
/* custom ant input */
.ant-input {
  border-radius: 6px;
  border: 1px solid #eaeaea;
}
.ant-input-affix-wrapper {
  border-radius: 6px;
  border: 1px solid #eaeaea;
}
/* end custom ant input */

/* start custom  ant-breadcrumb */
.ant-breadcrumb span.last-breadcrumb-items {
  font-size: 20px;
  color: #060606;
}

.ant-breadcrumb a:hover {
  color: #060606;
}

.ant-breadcrumb span.ant-breadcrumb-separator:last-child {
  font-size: 20px;
  font-weight: 200;
}
/* end custom  ant-breadcrumb */

.notification-container {
  background-color: #ffffff;
  width: 400px;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
}

.notification-content {
  overflow-y: auto;
  overflow-x: hidden;
  height: 400px;
}

.flex-col-center {
  display: -moz-box;
  display: flex;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
       flex-direction: column;
  -moz-box-align: center;
       align-items: center;
  -moz-box-pack: center;
       justify-content: center;
  height: 100%;
}
/* width */
/* ::-webkit-scrollbar {
  width: 2px;
}
::-webkit-scrollbar {
  width: 10px;
  border-radius: 3px;
}
/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 1px #dddddd;
          box-shadow: inset 0 0 1px #dddddd;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #dddddd;
}

::-webkit-scrollbar-thumb:hover {
  background: #333333;
  width: 10px;
}

.DraftEditor-root {
  width: 100%;
  position: relative;
}

.updload-img {
  background-color: #fff;
  position: absolute;
  left: 0;
  z-index: 1;
  padding: 8px;
  -moz-box-pack: center;
       justify-content: center;
  height: 324px;
  width: 100%;
  display: -moz-box;
  display: flex;
}

.dropped-image {
  margin: auto;
}

.dropped-image img {
  max-width: 100%;
  max-height: 306px;
}
.dropped-image:hover {
  background-color: #7e7e7e;
}

.flex-col-center {
  display: -moz-box;
  display: flex;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
       flex-direction: column;
  -moz-box-align: center;
       align-items: center;
  -moz-box-pack: center;
       justify-content: center;
  height: 100%;
}

.content-html {
  word-break: break-word;
}
.content-html img,
.content-html iframe {
  max-width: 100%;
  height: auto;
}

.tokhoquama {
  color: #0b7bff;
}

.DraftEditor-editorContainer {
  max-height: 100px;
  overflow-y: auto;
}

.draftJsEmojiPlugin__emojiSelectButton__3sPol {
  border: none;
  width: 32px;
  height: 32px;
  margin-right: 9px;
  background: #f5f5f5;
  color: #333333;
  font-size: 15px;
}

.list-item-comment .draftJsEmojiPlugin__emojiSelectButton__3sPol:hover {
  background: #eaeaea;
}

.popover-group-extension .ant-popover-inner-content {
  padding: 0px !important;
  overflow: hidden;
}

.popover-group-extension .ant-popover-inner {
  border-radius: 6px !important;
}

.item_extension_comment {
  padding: 8px 12px;
}

.item_extension_comment:hover {
  background: #f5f5f5;
}

.draftJsEmojiPlugin__emojiSelectButtonPressed__2Tezu {
  width: 32px;
  height: 32px;
  border: none;
  margin-right: 9px;
  background: #eaeaea;
}

.public-DraftEditorPlaceholder-root {
  color: #9197a3;
  position: absolute;
  z-index: 1;
}

/* class Name for component GiphyCard */
.giphy-search-box {
  width: 330px !important;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.giphy-scrollbar::-webkit-scrollbar {
  width: 2px;
}

.giphy-scrollbar::-webkit-scrollbar-thumb {
  background: #888888;
}

.giphy-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #333333;
}

.giphy-search-box-from input {
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  margin-bottom: 5px;
}
.PoweredByGiphy_poweredByGiphy__1MuEY {
  display: none;
}
.Spinner_spinnerWrapper__Xu_O3 {
  height: 100%;
  display: -moz-box;
  display: flex;
  -moz-box-pack: center;
       justify-content: center;
  -moz-box-align: center;
       align-items: center;
}
/*end class Name for component GiphyCard */
/* class Name for component Emoji */
.container-popup-emoji {
  min-width: 340px;
  min-height: 353px;
}
/* end class Name for component Emoji */
.loading-avatar {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
     -moz-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.nodata-pie-chart {
  position: absolute;
  top: 50%;
  right: 50%;
  -webkit-transform: translate(50%, -50%);
     -moz-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
}

.feeling-dropdown {
  /* top: 43px !important; */
  background: #ffffff;
  -webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding-top: 0;
}

.ant-group-title > .ant-form-item-label > label {
  color: #8b8b8b;
}

.list-item-comment {
  margin: 0px;
  margin-top: -2px;
  padding: 0px;
  -moz-box-align: center;
       align-items: center;
  -moz-box-pack: center;
       justify-content: center;
}

.list-item-comment .draftJsEmojiPlugin__emojiSelectButton__3sPol {
  background: transparent;
  margin: 0px;
  width: 24px;
  height: 24px;
}
.list-item-comment .draftJsEmojiPlugin__emojiSelectButtonPressed__2Tezu {
  width: 24px;
  height: 24px;
  margin-right: 0;
  cursor: pointer;
  font-size: 15px;
}
.form-total-comment .draftJsEmojiPlugin__emojiSelectPopover__1J1s0 {
  margin-top: 5px;
  padding: 0 0.3em;
  position: absolute;
  z-index: 1000;
  -webkit-box-sizing: content-box;
     -moz-box-sizing: content-box;
          box-sizing: content-box;
  background: #fff;
  border: 1px solid #e0e0e0;
  -webkit-box-shadow: 0 4px 30px 0 gainsboro;
          box-shadow: 0 4px 30px 0 gainsboro;
  right: 50px;
}
.draftJsEmojiPlugin__emojiSelectPopover__1J1s0::after {
  content: '';
  position: absolute;
  top: -10px;
  left: 12px;
  width: 0;
  height: 0;
  border-right: 8px solid transparent;
  border-bottom: 12px solid white;
  border-left: 8px solid transparent;
}
.form-total-comment .draftJsEmojiPlugin__emojiSelectPopover__1J1s0::after {
  left: auto;
  right: 10px;
}
.list-item-comment .draftJsEmojiPlugin__emojiSelectPopover__1J1s0 {
  -webkit-transform: scale(1) translateY(-100%);
     -moz-transform: scale(1) translateY(-100%);
          transform: scale(1) translateY(-100%);
  top: -10px;
}
.list-item-comment .draftJsEmojiPlugin__emojiSelectPopover__1J1s0::after {
  content: '';
  top: 100%;
  border-top: 12px solid white;
  border-bottom: 0;
}

.cursor-pointer,
p.cursor-pointer {
  cursor: pointer;
}
#interactive-activity .item-user-view-cmt .draftJsEmojiPlugin__emojiSelectPopover__1J1s0 {
  -webkit-transform: scale(0.8) translateY(-100%);
     -moz-transform: scale(0.8) translateY(-100%);
          transform: scale(0.8) translateY(-100%);
  right: -30px;
  top: -50px;
}
#content-activity {
  position: relative;
}

.contain-btn-container {
  position: absolute;
  top: 35px;
  right: 39px;
}

.contain-edit-btn {
  background: rgba(245, 245, 245, 0.3);
  border-radius: 4px;
  display: inline-block;
}
.contain-edit-btn:hover {
  background: #f5f5f5;
}

.contain-read-btn:hover,
.contain-read-btn:focus {
  color: #060606;
  border-color: #060606;
}

.btn-img-edit {
  background-color: whitesmoke !important;
  border-radius: 20px !important;
  margin-right: 9px;
}

.container-comment-interactive {
  height: auto;
  position: absolute;
  bottom: 0;
  background: white;
}
.contain-input-comment {
  width: 100%;
}
.form-total-comment {
  max-width: -moz-calc(100% - 47px);
  max-width: calc(100% - 47px);
}
.contain-input-comment {
  max-width: -moz-calc(100% - 72px);
  max-width: calc(100% - 72px);
}
#select-organizer .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  padding: 0;
  border: 0;
  background: transparent;
  margin-right: 16px;
}
#select-organizer .ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: 0;
}
.list-mention-organizer {
  border-radius: 8px;
}
.list-mention-organizer .ant-select-item {
  padding-top: 12px;
  padding-bottom: 12px;
}
.organizer-select-option {
  width: 100%;
}
.select-mention-organizer {
  position: relative;
}
.select-mention-organizer .ant-select-selection-item {
  -moz-box-align: center;
       align-items: center;
  height: 48px !important;
  border-radius: 6px;
}
.select-mention-organizer .ant-select-selector {
  height: auto !important;
}
/* css modal redeem reward */
.modal-wrap-confirm-reward {
  background-color: rgba(51, 51, 51, 0.5);
}
.modal-confirm-reward {
  width: 435px;
  height: 355px;
  padding: 0;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
}
.modal-confirm-reward .ant-modal-content {
  height: 100%;
  padding-top: 16px;
}
.modal-confirm-reward .ant-modal-close-icon {
  -webkit-transform: scale(1.3);
     -moz-transform: scale(1.3);
          transform: scale(1.3);
}
/* end css modal redeem reward */
.text-center {
  text-align: center !important;
}

.pt-0 {
  padding-top: 0;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.imt-4 {
  margin-top: 4px !important;
}

.mb-4 {
  margin-bottom: 4px;
}

.horizontal-menu {
  width: 100%;
}

.scroll-menu-arrow--disabled {
  visibility: hidden;
}

.ant-tabs {
  overflow: unset;
}

.items-wrap {
  width: 100%;
  height: auto;
  position: relative;
}

.item-img {
  width: 100%;
  height: 384px;
  cursor: pointer;
  position: relative;
}

.items-wrap.wrap-1 .item-img {
  display: -moz-box;
  display: flex;
}

.items-wrap.wrap-1 .item-img > img {
  width: auto;
  height: auto;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
}

.wrap-2 .item-img,
.wrap-3 .item-img,
.wrap-4 .item-img {
  width: 50%;
}

.wrap-5 .item-img {
  width: 25%;
}

.wrap-5 .item-img:first-child {
  width: 50%;
}

.wrap-3 .item-img:nth-child(2) {
  height: 192px;
}
.wrap-3 .item-img:nth-child(3) {
  height: 192px;
  position: absolute;
  left: 50%;
  top: 50%;
}

.wrap-4 .item-img:nth-child(2) {
  height: 192px;
}

.wrap-4 .item-img:nth-child(3) {
  width: 25%;
  height: 192px;
  position: absolute;
  left: 50%;
  top: 50%;
}

.wrap-4 .item-img:nth-child(4) {
  width: 25%;
  height: 192px;
  position: absolute;
  left: 75%;
  top: 50%;
}

.wrap-5 .item-img:nth-child(2) {
  height: 192px;
  position: absolute;
  left: 50%;
  top: 0;
}

.wrap-5 .item-img:nth-child(3) {
  height: 192px;
  position: absolute;
  left: 75%;
  top: 0;
}

.wrap-5 .item-img:nth-child(4) {
  height: 192px;
  position: absolute;
  left: 50%;
  top: 50%;
}

.wrap-5 .item-img:nth-child(5) {
  height: 192px;
  position: absolute;
  left: 75%;
  top: 50%;
}

.small-img {
  width: 50%;
  height: 192px;
}

.half-img {
  width: 100%;
  height: 192px;
}

.images-2 {
  width: 100%;
  height: 192px;
}

.images-3 {
  height: 192px;
  width: 100%;
}

.images-4 {
  width: 100%;
  height: 192px;
}

.image-gallery-fullscreen-button {
  top: 0;
  right: 16px;
  height: 20px;
  display: -moz-box;
  display: flex;
  position: absolute;
}

.image-gallery-fullscreen-button .image-gallery-svg.image-gallery-svg {
  width: 28px;
  height: 28px;
}

.active {
  top: 15px !important;
}

.modal-preview-images .ant-modal-content {
  -webkit-box-shadow: unset;
          box-shadow: unset;
  background-color: unset;
}

.modal-preview-images {
  width: 100vw;
}

.image-gallery-slides {
  padding: 45px 150px;
  height: 100vh;
}
.modal-preview-images .ant-modal {
  padding-bottom: 0;
}

.image-gallery-slides {
  display: -moz-box;
  display: flex;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
       flex-direction: column;
  -moz-box-pack: center;
       justify-content: center;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: -moz-calc(100vh - 136px);
  max-height: calc(100vh - 136px);
}

.imgPreview.edit-status video {
  max-width: 100%;
}

@-webkit-keyframes loaded {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes loaded {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes loaded {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

.flex {
  display: -moz-box;
  display: flex;
}

.ph-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.flex.center {
  -moz-box-align: center;
       align-items: center;
  -moz-box-pack: justify;
       justify-content: space-between;
}

.loaded:not(.has-error) {
  -webkit-animation: loaded 1000ms ease-in-out;
     -moz-animation: loaded 1000ms ease-in-out;
          animation: loaded 1000ms ease-in-out;
}

.top-trending__tag-name {
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.create-poll-form .ant-row.ant-form-item {
  margin-bottom: 0;
}

.create-poll-form .ant-col.ant-form-item-label {
  margin-bottom: 0;
}

.modal-voters-views .ant-modal-title {
  font-size: 22px;
  font-weight: 600;
}

.close-button {
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.poll-option-content {
  position: absolute;
  margin-left: 16px;
}

.edit-heatmap-modal .ant-modal-content {
  border-radius: 10px;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #e8e8e8;
}

.ant-message {
  z-index: 10011;
}

.fabric-canvas {
  height: 100%;
}

.popover-color-picker .ant-popover-inner-content {
  padding: 0 !important;
}

.popover-font-picker .ant-popover-inner-content {
  max-height: 200px;
  overflow: scroll;
}

.emoji-mart-emoji {
  vertical-align: middle;
  margin-top: -4px;
}

.custom-emoji {
  height: 22px;
}

.status-background-item.active > button {
  border: 2px solid #000;
}

.status-background-item > button {
  padding: 0;
  -webkit-box-sizing: content-box;
     -moz-box-sizing: content-box;
          box-sizing: content-box;
}

.content-padding {
  margin-left: 9%;
  margin-right: 9%;
}

.btn-upload-img {
  display: -moz-box;
  display: flex;
}

.btn-upload-img .ant-upload {
  display: -moz-box;
  display: flex;
}

.input-comment-image {
  position: absolute;
  bottom: 110%;
  right: 0;
  width: 130px;
  z-index: 1;
  -webkit-box-shadow: 0px 8px 24px #959da533;
          box-shadow: 0px 8px 24px #959da533;
}

.comment-item:nth-child(-n + 2) .input-comment-image {
  bottom: unset;
  top: 110%;
}

.cus-point-default {
  color: #000000d9;
}

.modal-status .ant-modal-content,
.modal-late-off .ant-modal-content,
.modal-voters-views .ant-modal-content,
.modal-edit-status .ant-modal-content,
.modal-preview .ant-modal-content {
  display: -moz-box;
  display: flex;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
       flex-direction: column;
  height: 100%;
  border-radius: 12px;
}

.modal-status .ant-modal-title,
.modal-late-off .ant-modal-title,
.modal-voters-views .ant-modal-title,
.modal-edit-status .ant-modal-title,
.modal-preview .ant-modal-title {
  font-size: 22px;
  font-weight: 500;
  font-family: 'Poppins';
}

.modal-status .ant-modal-header,
.modal-late-off .ant-modal-header,
.modal-voters-views .ant-modal-header,
.modal-edit-status .ant-modal-header,
.modal-preview .ant-modal-header {
  border-radius: 12px 12px 0 0;
}

.modal-status .ant-modal-footer {
  border-top: none;
  text-align: left;
  margin-bottom: 10px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #efefef;
          box-shadow: inset 0 0 6px #efefef;
  border-radius: 10px;
  background-color: #fafafa;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #c1c1c1;
}

.ant-picker-header-view {
  pointer-events: none;
}

.ghost-button {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.full-viewport-height {
  height: -moz-calc(100vh - 104px);
  height: calc(100vh - 104px); /* paddingTop: 20 + paddingBottom: 20 + Header height: 64 = 104 */
}

.profile-popup {
  z-index: 11;
}

.profile-popup .ant-popover-arrow {
  display: none;
}

.profile-popup .ant-popover-inner-content,
.profile-image-popup .ant-popover-inner-content {
  padding: 4px !important;
}

.skeleton-poll-vote .ant-skeleton {
  width: 100%;
}

.profile-image-ava {
  cursor: pointer;
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}

.profile-image-ava:hover {
  opacity: 0.9;
}

.profile-image-ava,
.profile-image-ava .ant-image-img {
  border-radius: 50%;
}

.profile-image-ava .ant-image-mask,
.profile-image-cover .ant-image-mask {
  display: none;
}

.ant-popover {
  z-index: 1000;
}

