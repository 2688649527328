body {
  background-color: #f2f4f4;
  font-size: 100%;
  line-height: 1.4;
  margin: 0;
  font-family: 'Nunito Sans', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*html:not([data-scroll='0']) {*/
/*  padding-top: 64px;*/
/*}*/

html:not([data-scroll='0']) header {
  position: sticky;
  z-index: 100;
  top: 0;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.form-total-comment {
  display: flex;
  background-color: #ffffff;
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 6px;
  width: 100%;
  padding: 7px 10px;
  margin-left: 15px;
  cursor: text;
  position: relative;
}

.form-total-comment ::-webkit-scrollbar {
  width: 2px;
}

.form-total-comment ::-webkit-scrollbar-track {
  background: white;
}

.form-total-comment ::-webkit-scrollbar-thumb {
  background: #888;
}
.modal-preview-content .ant-modal-content {
  box-shadow: none;
  height: 100%;
  overflow-y: auto;
}
.modal-preview-content .ant-modal-header {
  border-bottom: 0;
}
.modal-preview-content .ant-modal-body {
  font-size: 16px;
}
.input-comment-post {
  border-radius: 6px;
  width: 100%;
  resize: none;
  border: none;
  outline: none;
}
._4w79 {
  cursor: text;
  flex: 1 1 auto;
  line-height: 16px;
  overflow: hidden;
  color: #8d949e;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.list-interactive {
  list-style: none;
  display: flex;
  flex-direction: row;
  margin: 0px;
  padding: 0px;
  align-items: flex-end;
}
.ant-input:focus {
  box-shadow: none !important;
}
/* customer date picker ant design */
.ant-layout-content {
  padding-left: 32px;
  padding-right: 32px;
}
.ant-layout-content > div {
  transition: height 2s;
}
.typing-ellipsis {
  position: absolute;
  bottom: -18px;
  left: 0;
}
.comment-count {
  position: absolute;
  bottom: -18px;
  right: 0;
  font-size: 11px;
}

/* customer tool-tip ant design  START */
.ant-tooltip .ant-tooltip-inner {
  color: #000;
  background-color: #fff;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  font-size: 0.875rem;
}
.ant-tooltip .ant-tooltip-arrow::before {
  background-color: #fff;
}
.ant-tooltip-arrow span.ant-tooltip-arrow-content {
  background-color: #fff;
}

/* customer tool-tip ant design  END */

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #f5f5f5;
}
.popup-date-picker {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.08);
}
.ant-picker-header {
  border-bottom: 0;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 0;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner:hover {
  border: 0;
  background: rgba(0, 0, 0, 0.8);
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: #060606;
}
.ant-picker-cell .ant-picker-cell-inner {
  width: 33px;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.ant-picker-cell-today .ant-picker-cell-inner {
  background: rgba(6, 6, 6, 0.05);
  border-radius: 50%;
}
.ant-picker-content th {
  color: #8b8b8b;
}
/* .ant-picker-header-super-prev-btn {
  display: none;
}
.ant-picker-header-super-next-btn {
  display: none;
} */
.ant-picker-month-btn {
  font-size: 1rem;
  font-weight: 600;
}
.ant-picker-year-btn {
  font-size: 1rem;
  font-weight: 600;
}
.ant-picker-header {
  padding-top: 10px;
}

.ant-picker.heatmap-picker {
  border-radius: 4px;
  background-color: #f5f5f5 !important;
  width: 100%;
}

.heatmap-picker input {
  cursor: pointer;
}

.ant-picker-input input {
  font-size: 1rem;
  font-weight: 500;
  color: #060606;
}

/* end customer date picker ant design */
.h-full {
  height: 100%;
}
.w-full {
  width: 100%;
}
.pt-8 {
  padding-top: 8px;
}
.mb-16 {
  margin-bottom: 16px;
}
.mt-16 {
  margin-top: 16px;
}
.outside-container {
  background-color: #fafafa;
}
.layout-outside {
  max-width: 1200px;
  width: 100%;
  height: 100vh;
  margin: auto;
}
@media only screen and (max-width: 1200px) {
  .layout-outside {
    padding-left: 66px;
    padding-right: 66px;
  }
}
/* custom drop down menu */

/* .ant-dropdown-placement-bottomRight { */
.ant-dropdown-menu-light {
  padding: 0px !important;
  background-color: #ffffff !important;
  box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.08) !important;
  border-radius: 3px !important;
  color: #333333 !important;
  font-size: 16px !important;
}
.ant-popover-inner-content {
  padding: 4px 8px 4px 8px !important;
  border-radius: 6px !important;
}
/* } */
.ant-dropdown-menu {
  padding-top: 12px;
  padding-bottom: 12px;
}
.ant-dropdown-menu-item {
  padding-left: 14px;
  padding-right: 14px;
}
.ant-dropdown-menu-item:not(:last-child) {
  margin-bottom: 18px;
}
.icon-clock {
  margin-top: 13px;
}

.history-copy .ant-dropdown-menu {
  padding-top: 6px;
  padding-bottom: 6px;
}
.ant-select-item {
  min-height: auto;
}

.ant-comment-avatar img {
  border-radius: 6px;
}

.ant-comment-content {
  background: #f5f5f5;
  border-radius: 6px;
  padding: 9px 14px 7px 16px;
}

.ant-comment {
  width: 100%;
}
/* customer search input ant */
.ant-input-search-icon::before {
  border-left: none;
}
/* end customer search input ant */
::placeholder {
  font-size: 16px;
}

.ant-result-extra {
  display: flex;
  justify-content: center;
}
/* custom ant input */
.ant-input {
  border-radius: 6px;
  border: 1px solid #eaeaea;
}
.ant-input-affix-wrapper {
  border-radius: 6px;
  border: 1px solid #eaeaea;
}
/* end custom ant input */

/* start custom  ant-breadcrumb */
.ant-breadcrumb span.last-breadcrumb-items {
  font-size: 20px;
  color: #060606;
}

.ant-breadcrumb a:hover {
  color: #060606;
}

.ant-breadcrumb span.ant-breadcrumb-separator:last-child {
  font-size: 20px;
  font-weight: 200;
}
/* end custom  ant-breadcrumb */

.notification-container {
  background-color: #ffffff;
  width: 400px;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
}

.notification-content {
  overflow-y: auto;
  overflow-x: hidden;
  height: 400px;
}

.flex-col-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
/* width */
/* ::-webkit-scrollbar {
  width: 2px;
}
::-webkit-scrollbar {
  width: 10px;
  border-radius: 3px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px #dddddd;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #dddddd;
}

::-webkit-scrollbar-thumb:hover {
  background: #333333;
  width: 10px;
}

.DraftEditor-root {
  width: 100%;
  position: relative;
}

.updload-img {
  background-color: #fff;
  position: absolute;
  left: 0;
  z-index: 1;
  padding: 8px;
  justify-content: center;
  height: 324px;
  width: 100%;
  display: flex;
}

.dropped-image {
  margin: auto;
}

.dropped-image img {
  max-width: 100%;
  max-height: 306px;
}
.dropped-image:hover {
  background-color: #7e7e7e;
}

.flex-col-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.content-html {
  word-break: break-word;
}
.content-html img,
.content-html iframe {
  max-width: 100%;
  height: auto;
}

.tokhoquama {
  color: #0b7bff;
}

.DraftEditor-editorContainer {
  max-height: 100px;
  overflow-y: auto;
}

.draftJsEmojiPlugin__emojiSelectButton__3sPol {
  border: none;
  width: 32px;
  height: 32px;
  margin-right: 9px;
  background: #f5f5f5;
  color: #333333;
  font-size: 15px;
}

.list-item-comment .draftJsEmojiPlugin__emojiSelectButton__3sPol:hover {
  background: #eaeaea;
}

.popover-group-extension .ant-popover-inner-content {
  padding: 0px !important;
  overflow: hidden;
}

.popover-group-extension .ant-popover-inner {
  border-radius: 6px !important;
}

.item_extension_comment {
  padding: 8px 12px;
}

.item_extension_comment:hover {
  background: #f5f5f5;
}

.draftJsEmojiPlugin__emojiSelectButtonPressed__2Tezu {
  width: 32px;
  height: 32px;
  border: none;
  margin-right: 9px;
  background: #eaeaea;
}

.public-DraftEditorPlaceholder-root {
  color: #9197a3;
  position: absolute;
  z-index: 1;
}

/* class Name for component GiphyCard */
.giphy-search-box {
  width: 330px !important;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.giphy-scrollbar::-webkit-scrollbar {
  width: 2px;
}

.giphy-scrollbar::-webkit-scrollbar-thumb {
  background: #888888;
}

.giphy-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #333333;
}

.giphy-search-box-from input {
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  margin-bottom: 5px;
}
.PoweredByGiphy_poweredByGiphy__1MuEY {
  display: none;
}
.Spinner_spinnerWrapper__Xu_O3 {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
/*end class Name for component GiphyCard */
/* class Name for component Emoji */
.container-popup-emoji {
  min-width: 340px;
  min-height: 353px;
}
/* end class Name for component Emoji */
.loading-avatar {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.nodata-pie-chart {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}

.feeling-dropdown {
  /* top: 43px !important; */
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding-top: 0;
}

.ant-group-title > .ant-form-item-label > label {
  color: #8b8b8b;
}

.list-item-comment {
  margin: 0px;
  margin-top: -2px;
  padding: 0px;
  align-items: center;
  justify-content: center;
}

.list-item-comment .draftJsEmojiPlugin__emojiSelectButton__3sPol {
  background: transparent;
  margin: 0px;
  width: 24px;
  height: 24px;
}
.list-item-comment .draftJsEmojiPlugin__emojiSelectButtonPressed__2Tezu {
  width: 24px;
  height: 24px;
  margin-right: 0;
  cursor: pointer;
  font-size: 15px;
}
.form-total-comment .draftJsEmojiPlugin__emojiSelectPopover__1J1s0 {
  margin-top: 5px;
  padding: 0 0.3em;
  position: absolute;
  z-index: 1000;
  box-sizing: content-box;
  background: #fff;
  border: 1px solid #e0e0e0;
  box-shadow: 0 4px 30px 0 gainsboro;
  right: 50px;
}
.draftJsEmojiPlugin__emojiSelectPopover__1J1s0::after {
  content: '';
  position: absolute;
  top: -10px;
  left: 12px;
  width: 0;
  height: 0;
  border-right: 8px solid transparent;
  border-bottom: 12px solid white;
  border-left: 8px solid transparent;
}
.form-total-comment .draftJsEmojiPlugin__emojiSelectPopover__1J1s0::after {
  left: auto;
  right: 10px;
}
.list-item-comment .draftJsEmojiPlugin__emojiSelectPopover__1J1s0 {
  transform: scale(1) translateY(-100%);
  top: -10px;
}
.list-item-comment .draftJsEmojiPlugin__emojiSelectPopover__1J1s0::after {
  content: '';
  top: 100%;
  border-top: 12px solid white;
  border-bottom: 0;
}

.cursor-pointer,
p.cursor-pointer {
  cursor: pointer;
}
#interactive-activity .item-user-view-cmt .draftJsEmojiPlugin__emojiSelectPopover__1J1s0 {
  transform: scale(0.8) translateY(-100%);
  right: -30px;
  top: -50px;
}
#content-activity {
  position: relative;
}

.contain-btn-container {
  position: absolute;
  top: 35px;
  right: 39px;
}

.contain-edit-btn {
  background: rgba(245, 245, 245, 0.3);
  border-radius: 4px;
  display: inline-block;
}
.contain-edit-btn:hover {
  background: #f5f5f5;
}

.contain-read-btn:hover,
.contain-read-btn:focus {
  color: #060606;
  border-color: #060606;
}

.btn-img-edit {
  background-color: whitesmoke !important;
  border-radius: 20px !important;
  margin-right: 9px;
}

.container-comment-interactive {
  height: auto;
  position: absolute;
  bottom: 0;
  background: white;
}
.contain-input-comment {
  width: 100%;
}
.form-total-comment {
  max-width: calc(100% - 47px);
}
.contain-input-comment {
  max-width: calc(100% - 72px);
}
#select-organizer .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  padding: 0;
  border: 0;
  background: transparent;
  margin-right: 16px;
}
#select-organizer .ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: 0;
}
.list-mention-organizer {
  border-radius: 8px;
}
.list-mention-organizer .ant-select-item {
  padding-top: 12px;
  padding-bottom: 12px;
}
.organizer-select-option {
  width: 100%;
}
.select-mention-organizer {
  position: relative;
}
.select-mention-organizer .ant-select-selection-item {
  align-items: center;
  height: 48px !important;
  border-radius: 6px;
}
.select-mention-organizer .ant-select-selector {
  height: auto !important;
}
/* css modal redeem reward */
.modal-wrap-confirm-reward {
  background-color: rgba(51, 51, 51, 0.5);
}
.modal-confirm-reward {
  width: 435px;
  height: 355px;
  padding: 0;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
}
.modal-confirm-reward .ant-modal-content {
  height: 100%;
  padding-top: 16px;
}
.modal-confirm-reward .ant-modal-close-icon {
  transform: scale(1.3);
}
/* end css modal redeem reward */
.text-center {
  text-align: center !important;
}

.pt-0 {
  padding-top: 0;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.imt-4 {
  margin-top: 4px !important;
}

.mb-4 {
  margin-bottom: 4px;
}

.horizontal-menu {
  width: 100%;
}

.scroll-menu-arrow--disabled {
  visibility: hidden;
}

.ant-tabs {
  overflow: unset;
}

.items-wrap {
  width: 100%;
  height: auto;
  position: relative;
}

.item-img {
  width: 100%;
  height: 384px;
  cursor: pointer;
  position: relative;
}

.items-wrap.wrap-1 .item-img {
  display: flex;
}

.items-wrap.wrap-1 .item-img > img {
  width: auto;
  height: auto;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
}

.wrap-2 .item-img,
.wrap-3 .item-img,
.wrap-4 .item-img {
  width: 50%;
}

.wrap-5 .item-img {
  width: 25%;
}

.wrap-5 .item-img:first-child {
  width: 50%;
}

.wrap-3 .item-img:nth-child(2) {
  height: 192px;
}
.wrap-3 .item-img:nth-child(3) {
  height: 192px;
  position: absolute;
  left: 50%;
  top: 50%;
}

.wrap-4 .item-img:nth-child(2) {
  height: 192px;
}

.wrap-4 .item-img:nth-child(3) {
  width: 25%;
  height: 192px;
  position: absolute;
  left: 50%;
  top: 50%;
}

.wrap-4 .item-img:nth-child(4) {
  width: 25%;
  height: 192px;
  position: absolute;
  left: 75%;
  top: 50%;
}

.wrap-5 .item-img:nth-child(2) {
  height: 192px;
  position: absolute;
  left: 50%;
  top: 0;
}

.wrap-5 .item-img:nth-child(3) {
  height: 192px;
  position: absolute;
  left: 75%;
  top: 0;
}

.wrap-5 .item-img:nth-child(4) {
  height: 192px;
  position: absolute;
  left: 50%;
  top: 50%;
}

.wrap-5 .item-img:nth-child(5) {
  height: 192px;
  position: absolute;
  left: 75%;
  top: 50%;
}

.small-img {
  width: 50%;
  height: 192px;
}

.half-img {
  width: 100%;
  height: 192px;
}

.images-2 {
  width: 100%;
  height: 192px;
}

.images-3 {
  height: 192px;
  width: 100%;
}

.images-4 {
  width: 100%;
  height: 192px;
}

.image-gallery-fullscreen-button {
  top: 0;
  right: 16px;
  height: 20px;
  display: flex;
  position: absolute;
}

.image-gallery-fullscreen-button .image-gallery-svg.image-gallery-svg {
  width: 28px;
  height: 28px;
}

.active {
  top: 15px !important;
}

.modal-preview-images .ant-modal-content {
  box-shadow: unset;
  background-color: unset;
}

.modal-preview-images {
  width: 100vw;
}

.image-gallery-slides {
  padding: 45px 150px;
  height: 100vh;
}
.modal-preview-images .ant-modal {
  padding-bottom: 0;
}

.image-gallery-slides {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: calc(100vh - 136px);
}

.imgPreview.edit-status video {
  max-width: 100%;
}

@keyframes loaded {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

.flex {
  display: flex;
}

.ph-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.flex.center {
  align-items: center;
  justify-content: space-between;
}

.loaded:not(.has-error) {
  animation: loaded 1000ms ease-in-out;
}

.top-trending__tag-name {
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.create-poll-form .ant-row.ant-form-item {
  margin-bottom: 0;
}

.create-poll-form .ant-col.ant-form-item-label {
  margin-bottom: 0;
}

.modal-voters-views .ant-modal-title {
  font-size: 22px;
  font-weight: 600;
}

.close-button {
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.poll-option-content {
  position: absolute;
  margin-left: 16px;
}

.edit-heatmap-modal .ant-modal-content {
  border-radius: 10px;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #e8e8e8;
}

.ant-message {
  z-index: 10011;
}

.fabric-canvas {
  height: 100%;
}

.popover-color-picker .ant-popover-inner-content {
  padding: 0 !important;
}

.popover-font-picker .ant-popover-inner-content {
  max-height: 200px;
  overflow: scroll;
}

.emoji-mart-emoji {
  vertical-align: middle;
  margin-top: -4px;
}

.custom-emoji {
  height: 22px;
}

.status-background-item.active > button {
  border: 2px solid #000;
}

.status-background-item > button {
  padding: 0;
  box-sizing: content-box;
}

.content-padding {
  margin-left: 9%;
  margin-right: 9%;
}

.btn-upload-img {
  display: flex;
}

.btn-upload-img .ant-upload {
  display: flex;
}

.input-comment-image {
  position: absolute;
  bottom: 110%;
  right: 0;
  width: 130px;
  z-index: 1;
  box-shadow: 0px 8px 24px #959da533;
}

.comment-item:nth-child(-n + 2) .input-comment-image {
  bottom: unset;
  top: 110%;
}

.cus-point-default {
  color: #000000d9;
}

.modal-status .ant-modal-content,
.modal-late-off .ant-modal-content,
.modal-voters-views .ant-modal-content,
.modal-edit-status .ant-modal-content,
.modal-preview .ant-modal-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 12px;
}

.modal-status .ant-modal-title,
.modal-late-off .ant-modal-title,
.modal-voters-views .ant-modal-title,
.modal-edit-status .ant-modal-title,
.modal-preview .ant-modal-title {
  font-size: 22px;
  font-weight: 500;
  font-family: 'Poppins';
}

.modal-status .ant-modal-header,
.modal-late-off .ant-modal-header,
.modal-voters-views .ant-modal-header,
.modal-edit-status .ant-modal-header,
.modal-preview .ant-modal-header {
  border-radius: 12px 12px 0 0;
}

.modal-status .ant-modal-footer {
  border-top: none;
  text-align: left;
  margin-bottom: 10px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #efefef;
  border-radius: 10px;
  background-color: #fafafa;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #c1c1c1;
}

.ant-picker-header-view {
  pointer-events: none;
}

.ghost-button {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.full-viewport-height {
  height: calc(100vh - 104px); /* paddingTop: 20 + paddingBottom: 20 + Header height: 64 = 104 */
}

.profile-popup {
  z-index: 11;
}

.profile-popup .ant-popover-arrow {
  display: none;
}

.profile-popup .ant-popover-inner-content,
.profile-image-popup .ant-popover-inner-content {
  padding: 4px !important;
}

.skeleton-poll-vote .ant-skeleton {
  width: 100%;
}

.profile-image-ava {
  cursor: pointer;
  transition: all 200ms ease-in-out;
}

.profile-image-ava:hover {
  opacity: 0.9;
}

.profile-image-ava,
.profile-image-ava .ant-image-img {
  border-radius: 50%;
}

.profile-image-ava .ant-image-mask,
.profile-image-cover .ant-image-mask {
  display: none;
}

.ant-popover {
  z-index: 1000;
}
