.sticker-picker{
    max-width: 266px;
}

.sticker-picker .sp-tab-head .arrow-prev,
.sticker-picker .sp-tab-head .arrow-next{
    padding: 0 5px;
}

.sticker-picker .sp-tab-head-item {
    transition: 0.5s ease;
}

.sticker-picker .sp-tab-head-item.active {
    background-color: #e2e2e2;
}

.sticker-picker .sp-tab-head-item img {
    width: 25px;
    height: 25px;
    margin: 7.5px;
}

.sticker-picker .sp-sticker-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 5px;
    max-height: 210px;
    overflow-y: auto;
}

.sticker-picker .sp-sticker-item {
    cursor: pointer;
    transition: 0.5s ease;
}

.sticker-picker .sp-sticker-item:hover{
    background-color: #d2d2d2;
    border-radius: 8px;
}

.sticker-picker .sp-sticker-item img {
    width: 64px;
    height: 64px;
}