.image-gallery-right-nav, .image-gallery-left-nav {
  padding: 0;
  width: 56px;
  height: 56px;
  margin: 0 32px;
  border-radius: 50%;
  background: rgba(245, 245, 245, 0.4);
}
.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  width: 22px;
  height: auto;
}

.image-gallery-right-nav .image-gallery-svg {
  margin-left: 4px;
}
.image-gallery-left-nav .image-gallery-svg {
  margin-right: 4px;
}

.image-gallery-slide .image-gallery-image {
  width: auto;
  max-width: 100%;
  object-fit: contain;
}

.image-gallery-slide.center div{
  width: max-content;
  margin: 0 auto;
}